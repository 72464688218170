<!--
 * @Author: your name
 * @Date: 2021-01-27 11:06:28
 * @LastEditTime: 2021-02-02 18:48:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \weilaiyouni_web\src\views\index\default.vue
-->
<template>
  <div>
    <!-- <h1 style="margin-top:150px;font-size:28px;">欢迎来到未来油你管理后台</h1> -->
    <el-row :gutter="15">
      <el-col :span="6">
        <div class="layout-box layout-box-color">
          <img class="layout-icon" :src="require('@/assets/img/today.png')" />
          <h2>今日统计</h2>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="layout-box">
          <div class="item-box">
            <img class="item-icon" :src="require('@/assets/img/jq.png')" />
            <div class="text-box">
              <p>今日发放金额</p>
              <p v-text="Number(items.todayAmount)+'元'"></p>
            </div>
          </div>
          <div class="item-box" style="border-top:2px solid rgb(45,140,240);border-bottom:2px solid rgb(45,140,240)">
            <img class="item-icon" :src="require('@/assets/img/kf.png')" />
            <div class="text-box">
              <p>今日发放客户数量</p>
              <p v-text="Number(items.todayCustomerNumber)+'人'"></p>
            </div>
          </div>
          <div class="item-box">
            <img class="item-icon" :src="require('@/assets/img/je.png')" />
            <div class="text-box">
              <p>今日核销金额</p>
              <p v-text="Number(items.todayUsedAmount)+'元'"></p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="layout-box layout-box-color">
          <img class="layout-icon" :src="require('@/assets/img/by.png')" />
          <h2>本月统计</h2>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="layout-box">
          <div class="item-box">
            <img class="item-icon" :src="require('@/assets/img/jq.png')" />
            <div class="text-box">
              <p>本月发放金额</p>
              <p v-text="Number(items.monthAmount)+'元'"></p>
            </div>
          </div>
          <div class="item-box" style="border-top:2px solid rgb(45,140,240);border-bottom:2px solid rgb(45,140,240)">
            <img class="item-icon" :src="require('@/assets/img/kf.png')" />
            <div class="text-box">
              <p>本月发放客户数量</p>
              <p v-text="Number(items.monthCustomerNumber)+'人'"></p>
            </div>
          </div>
          <div class="item-box">
            <img class="item-icon" :src="require('@/assets/img/je.png')" />
            <div class="text-box">
              <p>本月核销金额</p>
              <p v-text="Number(items.monthUsedAmount)+'元'"></p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div style="height:515px;margin-top:25px" class="layout-box layout-box-color">
          <img class="layout-icon" :src="require('@/assets/img/sp.png')" />
          <h2>待审批</h2>
        </div>
      </el-col>
      <el-col :span="18">
        <ApprovalList type="home" class="layout-box" style="height:515px;margin-top:25px"></ApprovalList>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { homePageCount } from '@/api/clearing'
import ApprovalList from '../approval/index.vue'
export default {
  components: {
    ApprovalList
  },
  name: 'default',
  data() {
    return {
      items: {
        monthAmount: 0,
        monthCustomerNumber: 0,
        monthUsedAmount: 0,
        todayAmount: 0,
        todayCustomerNumber: 0,
        todayUsedAmount: 0,
      }
    }
  },
  methods: {

  },
  mounted() {
    let that = this;
    homePageCount().
      then((response) => {
        that.items = response.data
        console.log(response);
      })
  }
}
</script>
<style scoped>
.layout-box-color {
  background-color: rgb(45, 140, 240);
  border: 1px solod rgb(45, 140, 240);
}
.layout-box {
  height: 300px;
  margin: 10px 10px 0 10px;
  box-sizing: border-box;
  border: 1px solid rgb(214, 214, 214);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.layout-icon {
  width: 25%;
}
.layout-box h2 {
  font-size: 30px;
  font-weight: 100;
  color: #ffffff;
  margin: 50px 0px 0px;
}
.item-box {
  position: relative;
  width: 96%;
  margin: 0px 2%;
  height: 32%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text-box {
  width: 78%;
}
.text-box p {
  font-size: 20px;
  margin: 10px 0px;
  color: rgb(45, 140, 240);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-box p:nth-child(odd) {
  font-size: 16px;
}
.item-icon {
  width: 18%;
  margin: 0px 0px 0px 2%;
}
</style>